<template>
    <div>
        <div class="auth-wrapper auth-v2">
            <div class="auth-inner">
                <v-row class="auth-row ma-0">
                    <v-col lg="7"
                           class="d-none d-lg-block position-relative overflow-hidden pa-0">
                        <div class="auth-illustrator-wrapper login-bg"></div>
                    </v-col>

                    <v-col lg="5"
                           style="overflow: auto; height: 100vh; position: relative"
                           class="auth-bg pa-lg-10 pa-md-10 pa-sm-4 pb-0 pt-10">
                        <div v-if="showAlert">
                            <alert :message="alertMessage"
                                   :type="alertType"></alert>
                        </div>
                        <v-row style="justify-content: end">
                            <div class="d-flex align-right right-row">
                                <app-bar-i18n></app-bar-i18n>
                                <!-- <v-icon>mdi-power-on</v-icon> -->
                                <!-- <app-bar-theme-switcher></app-bar-theme-switcher> -->
                            </div>
                        </v-row>

                        <v-row class="pt-lg-16">
                            <v-col cols="12"
                                   sm="8"
                                   md="6"
                                   lg="12"
                                   class="mx-auto">
                                <div v-if="$vuetify.breakpoint.smAndDown">
                                    <v-img contain
                                           max-width="100%"
                                           class="auth-3d-group overflow-hidden"
                                           :src="require(`@/assets/images/banner.png`)">
                                    </v-img>
                                </div>
                                <v-card flat>
                                    <v-card-text>
                                        <v-img contain
                                               max-width="100%"
                                               class="auth-3d-group overflow-hidden"
                                               :src="require(`@/assets/images/centurion/centlogo.png`)">
                                        </v-img>
                                        <p v-if="!isOtpGenerated"
                                           class="arabic-font text-2xl font-weight-semibold text-black text-center pb-2 pt-5">
                                            {{ $t('Sign In') }}
                                        </p>
                                        <p v-if="isOtpGenerated"
                                           class="text-center text-2xl font-weight-semibold text-black pt-3"
                                           style="margin-bottom: 0;position: relative;">
                                            <v-icon class="arabic-arrow ar-forgotid en-forgotid"
                                                    color="green-darken-2"
                                                    @click="isOtpGenerated = false">
                                                mdi-arrow-left
                                            </v-icon>
                                            {{ $t('Sign In') }}
                                        </p>
                                    </v-card-text>
                                    <!-- login form -->
                                    <v-card-text>
                                        <div v-if="isOtpGenerated"
                                             style="margin: auto">
                                            <div>
                                                <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-8"
                                                   style="font-size: 14px">
                                                    {{ $t('Please enter authentication code sent to you over SMS.') }}
                                                </p>
                                                <form @submit.prevent="verifyOTP">
                                                    <div dir="ltr"
                                                         class="otp-input"
                                                         style="max-width: 300px">
                                                        <v-otp-input v-model="otp"
                                                                     :rules="otpRules"
                                                                     @input="onOtpInput"
                                                                     @blur="onInputBlur"
                                                                     ref="otpInput"
                                                                     :hide-details="true"
                                                                     background="#000"
                                                                     type="number"
                                                                     @keydown.enter="verifyOTP"
                                                                     onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                     length="4">
                                                        </v-otp-input>
                                                        <div v-if="otpError"
                                                             style="font-size: 12px"
                                                             class="ar-otp error-message">
                                                            {{ $t(otpErrorMessage) }}
                                                        </div>
                                                        <timer :showTimer="showTimer"
                                                               :formattedTime="formattedTime"
                                                               :selectedLanguage="selectedLanguage"
                                                               @retry="sendOtpAgain"></timer>
                                                    </div>
                                                </form>

                                                <v-btn color="primary"
                                                       type="button"
                                                       style="width: 100%"
                                                       :disabled="otp.length < 4"
                                                       @click="verifyOTP"
                                                       x-large
                                                       class="arabic-font mt-6 py-3">
                                                    <span style="color: black;">{{ $t('Submit') }}</span>
                                                </v-btn>
                                            </div>
                                        </div>

                                        <v-form v-if="!isOtpGenerated"
                                                ref="loginForm"
                                                @submit.prevent="generateOtp">
                                            <p class="arabic-font font-weight-semibold text-black mb-2">
                                                {{ $t('User ID') }}
                                            </p>

                                            <v-text-field v-model="userName"
                                                          outlined
                                                          :placeholder="$t('Enter your User ID')"
                                                          :rules="genericRules"
                                                          hide-details="auto"
                                                          v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'"
                                                          class="mb-6">
                                                <template #message="{ message }">
                                                    {{ $t(message) }}
                                                </template>
                                            </v-text-field>

                                            <p class="arabic-font font-weight-semibold text-black mb-2">
                                                {{ $t('Password') }}
                                            </p>
                                            <v-text-field v-model="password"
                                                          outlined
                                                          :type="isPasswordVisible ? 'text' : 'password'"
                                                          :placeholder="$t('Enter your Password')"
                                                          :append-icon="password ? (isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline) : ''
                                                            "
                                                          :rules="passwordRules"
                                                          v-mask="'XXXXXX'"
                                                          hide-details="auto"
                                                          class="mb-2"
                                                          @click:append="isPasswordVisible = !isPasswordVisible">
                                                <template #message="{ message }">
                                                    {{ $t(message) }}
                                                </template>
                                            </v-text-field>
                                            <v-btn block
                                                   color="primary"
                                                   type="submit"
                                                   large
                                                   class="mt-6">
                                                <span style="color: black;">{{ $t('Login') }}</span>
                                            </v-btn>

                                            <div
                                                 class="arabic-font d-lg-flex d-block align-center font-weight-bold pt-8">
                                                <router-link :to="{ name: 'enrollment' }">
                                                    {{ $t('New User?') }}
                                                </router-link>
                                                <v-spacer></v-spacer>
                                                <router-link :to="{ name: 'auth-forgot-id' }">
                                                    {{ $t('Forgot User ID?') }}
                                                </router-link>


                                                <v-spacer></v-spacer>
                                                <router-link :to="{ name: 'auth-forgot-password' }">
                                                    {{ $t('Forgot Password?') }}
                                                </router-link>
                                            </div>
                                            <div
                                                 class="d-flex align-center justify-space-around flex-wrap font-weight-bold pt-10">
                                                <div style="cursor: pointer; color: #016fd0 !important"
                                                     @click="publicContact = true"
                                                     class="arabic-font text-center text-blue">
                                                    <v-img contain
                                                           max-width="20px"
                                                           class="auth-3d-group overflow-hidden mx-auto mb-2"
                                                           :src="require(`@/assets/images/login/phone.png`)">
                                                    </v-img>
                                                    <span style="color: white">{{ $t('Contact Us') }}</span>
                                                </div>
                                                <div style="cursor: pointer; color: #016fd0 !important"
                                                     @click="modalTerms = true"
                                                     class="arabic-font text-center text-blue">
                                                    <v-img contain
                                                           max-width="20px"
                                                           class="auth-3d-group overflow-hidden mx-auto mb-2"
                                                           :src="require(`@/assets/images/login/Vector.png`)">
                                                    </v-img>
                                                    <span style="color: white">{{ $t('Terms of Use') }}</span>
                                                </div>

                                                <v-dialog v-model="modalTerms"
                                                          width="80%">
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="text-h5 text-color-black">
                                                                {{ $t('Terms of Use') }}
                                                            </span>
                                                            <v-spacer></v-spacer>
                                                            <span style="cursor: pointer"
                                                                  @click="modalTerms = false"
                                                                  class="arabic-close en-close">
                                                                <v-icon>
                                                                    {{ icons.mdiClose }}
                                                                </v-icon>
                                                            </span>
                                                        </v-card-title>

                                                        <v-tabs v-model="tab"
                                                                dark>
                                                            <v-tab>
                                                                {{ $t(`Terms & Conditions`) }}
                                                            </v-tab>
                                                            <v-tab>
                                                                {{ $t('Privacy Statement') }}
                                                            </v-tab>
                                                        </v-tabs>
                                                        <v-tabs-items v-model="tab">
                                                            <v-tab-item>
                                                                <TermConditionAr v-if="selectedLanguage == 'ar'">
                                                                </TermConditionAr>
                                                                <TermConditionEn v-if="selectedLanguage == 'en'">
                                                                </TermConditionEn>
                                                            </v-tab-item>
                                                            <v-tab-item>
                                                                <PrivacyTranslationAr v-if="selectedLanguage == 'ar'">
                                                                </PrivacyTranslationAr>
                                                                <PrivacyTranslationEn v-if="selectedLanguage == 'en'">
                                                                </PrivacyTranslationEn>
                                                            </v-tab-item>
                                                        </v-tabs-items>
                                                    </v-card>
                                                </v-dialog>

                                                <v-dialog v-model="publicContact"
                                                          style="overflow: hidden;">
                                                    <v-card class="public-contact">
                                                        <v-card-title>
                                                            <span class="text-h5 text-color-black">
                                                                {{ $t(`Contact Us`) }}
                                                            </span>
                                                            <v-spacer></v-spacer>
                                                            <span style="cursor: pointer"
                                                                  @click="publicContact = false"
                                                                  class="arabic-close en-close">
                                                                <v-icon>
                                                                    {{ icons.mdiClose }}
                                                                </v-icon>
                                                            </span>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <PublicContact></PublicContact>

                                                        </v-card-text>
                                                    </v-card>
                                                </v-dialog>

                                            </div>

                                            <p class="arabic-font text--primary mb-2 pt-5 text-center"
                                               style="font-size: 12px">
                                                {{ $t(`public_auth_long_text1`) }} <br><br>
                                                {{ $t(`public_auth_long_text`) }}
                                            </p>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline, mdiClose } from '@mdi/js'
import timer from '@/@core/timer.vue'
import authServices from '@/services/auth-services'
import sharedServices from '@/services/shared-services'
import store from '@/store'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import TermsCondition from '@/views/TermsCondition.vue'
import alert from '../views/alert.vue'
import router from '@/router'
import restErrorMessages from '@/@core/utils/rest-error-messages'

import TermConditionAr from './term-and-policy/terms-condition-translation/TermConditionAr.vue'
import TermConditionEn from './term-and-policy/terms-condition-translation/TermConditionEn.vue'

import PrivacyTranslationAr from './term-and-policy/privacy-policy-translation/PrivacyTranslationAr.vue'
import PrivacyTranslationEn from './term-and-policy/privacy-policy-translation/PrivacyTranslationEn.vue'

import PublicContact from './contact-us/public-contact/PublicContact.vue';

import { getAnalytics, logEvent } from "firebase/analytics";

import { getVuetify } from '@core/utils'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        TermsCondition,
        alert,
        TermConditionAr,
        TermConditionEn,
        PrivacyTranslationAr,
        PrivacyTranslationEn,
        timer,
        PublicContact
    },
    data: () => ({
        $vuetify: getVuetify(),
        isDark: useAppConfig(),
        tab: null,
        analytics: getAnalytics(),
        otpError: false,
        otpErrorMessage: '',
        otp: '',
        showTimer: false,
        time: 60,
        timerId: null,
        otpRules: [v => !!v || 'Field is required'],
        valid: false,
        dialog: false,
        modall: false,
        modalTerms: false,
        genericRules: [v => !!v || 'User ID is required'],
        passwordRules: [
            value => !!value || 'Password is required.',
            value => (value && value.length >= 6) || 'password_info',
        ],
        publicContact: false,
        loginForm: null,
        isPasswordVisible: false,
        userName: store.get('requestKeys/userName'),
        password: '',
        showAlert: false,
        alertMessage: '',
        alertType: 'error',
        selectedLanguage: 'en',
        icons: {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiClose,
        },

        isOtpGenerated: false,
        loginRes: null,
    }),
    mounted()
    {
        this.$vuetify.theme.dark = true;

        let lang = localStorage.getItem('language')
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language')
        }
        window.scrollTo(0, 0)
    },
    computed: {
        formattedTime()
        {
            const minutes = Math.floor(this.time / 60)
            const seconds = this.time % 60
            return `${seconds.toString().padStart(2, '0')}`
        },
        mr3Class()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'ml-3'
            } else
            {
                return 'mr-3'
            }
        },
    },
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload
            }
        })
    },
    methods: {
        onOtpInput()
        {
            if (this.otp.length !== 4)
            {
                this.otpError = true
                this.otpErrorMessage = 'Field is required';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false
                this.$nextTick(() =>
                {
                    const inputElement = this.$refs.otpInput.$el.querySelector('input');
                    if (inputElement)
                    {
                        inputElement.focus(); // Move focus back into the input
                    }
                });
            }
        },
        onInputBlur()
        {
            if (!this.otp.length)
            {
                this.otpError = true
                this.otpErrorMessage = 'Field is required'
            }
        },
        openPopup()
        {
            if (this.modall == false)
            {
                this.modall = true
            } else
            {
                this.modall = false
            }
        },
        closeDialog()
        {
            this.modall = false
        },
        generateOtp()
        {
            const isFormValid = this.$refs.loginForm.validate()

            if (!isFormValid) return

            sharedServices.getToken('login', this.userName).then(tokenRes =>
            {
                let data = {
                    requestInfo: {
                        channelKey: tokenRes.data.results.channelKey,
                        identityKey: tokenRes.data.results.identityKey,
                        requestDate: tokenRes.data.results.requestDate,
                        requestUID: Math.floor(1000 + Math.random() * 9000).toString(),
                        channelId: store.get('requestKeys/channelId'),
                        userName: this.userName,
                        identitySeed: sharedServices.getIdSeed(),
                    },
                    passwordHash: this.password,
                }

                authServices.login(data).then(loginRes =>
                {
                    this.loginRes = loginRes

                    let errorCode = loginRes?.data?.responseInfo?.errorCode
                    let errorDescription = loginRes?.data?.responseInfo?.errorDescription

                    if (errorCode == '00000')
                    {
                        authServices.generateOtp(data).then(gOtp =>
                        {
                            let errorCode = gOtp?.data?.responseInfo?.errorCode
                            let errorDescription = gOtp?.data?.responseInfo?.errorDescription

                            if (errorCode == '00000')
                            {
                                this.showAlert = true
                                this.alertMessage = restErrorMessages.get('222222', errorDescription)
                                this.alertType = 'success'
                                setTimeout(() =>
                                {
                                    this.showAlert = false
                                }, 5000)

                                this.isOtpGenerated = true
                                this.showTimer = true
                                this.timerId = setInterval(() =>
                                {
                                    this.time -= 1
                                    if (this.time <= 0)
                                    {
                                        clearInterval(this.timerId)
                                        this.showTimer = false
                                        this.time = 60
                                        this.timerId = null
                                    }
                                }, 1000)
                            } else
                            {
                                this.showAlert = true
                                this.alertMessage = restErrorMessages.get(errorCode, errorDescription)
                                this.alertType = 'error'
                                setTimeout(() =>
                                {
                                    this.showAlert = false
                                }, 5000)
                            }
                        })
                    } else
                    {
                        if (errorCode == '80020')
                        {
                            errorCode = '989898'
                        }
                        this.showAlert = true
                        this.alertMessage = restErrorMessages.get(errorCode, errorDescription)
                        this.alertType = 'error'
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                    // window.location.reload();
                })
            })
        },
        sendOtpAgain()
        {
            sharedServices.getToken('login', this.userName).then(tokenRes =>
            {
                let data = {
                    requestInfo: {
                        channelKey: tokenRes.data.results.channelKey,
                        identityKey: tokenRes.data.results.identityKey,
                        requestDate: tokenRes.data.results.requestDate,
                        requestUID: Math.floor(1000 + Math.random() * 9000).toString(),
                        channelId: store.get('requestKeys/channelId'),
                        userName: this.userName,
                        identitySeed: sharedServices.getIdSeed(),
                    },
                    passwordHash: this.password,
                }
                authServices.generateOtp(data).then(gOtp =>
                {
                    let errorCode = gOtp?.data?.responseInfo?.errorCode
                    let errorDescription = gOtp?.data?.responseInfo?.errorDescription

                    if (errorCode == '00000')
                    {
                        this.showAlert = true
                        this.alertMessage = restErrorMessages.get('222222', errorDescription)
                        this.alertType = 'success'
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)

                        this.isOtpGenerated = true
                        this.showTimer = true
                        this.timerId = setInterval(() =>
                        {
                            this.time -= 1
                            if (this.time <= 0)
                            {
                                clearInterval(this.timerId)
                                this.showTimer = false
                                this.time = 60
                                this.timerId = null
                            }
                        }, 1000)
                    } else
                    {
                        this.showAlert = true
                        this.alertMessage = restErrorMessages.get(errorCode, errorDescription)
                        this.alertType = 'error'
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                })
            })
        },
        verifyOTP()
        {
            if (this.otp.length != 4)
            {
                return;
            }
            sharedServices.getToken('login', this.userName).then(tokenRes =>
            {
                let data = {
                    requestInfo: {
                        channelKey: tokenRes.data.results.channelKey,
                        identityKey: tokenRes.data.results.identityKey,
                        requestDate: tokenRes.data.results.requestDate,
                        requestUID: Math.floor(1000 + Math.random() * 9000).toString(),
                        channelId: store.get('requestKeys/channelId'),
                        userName: this.userName,
                        identitySeed: sharedServices.getIdSeed(),
                    },
                    otp: this.otp,
                }

                authServices.verifyOTP(data).then(vOtp =>
                {
                    let errorCode = vOtp?.data?.responseInfo?.errorCode
                    let errorDescription = vOtp?.data?.responseInfo?.errorDescription

                    if (errorCode == '0000')
                    {
                        this.handleFormSubmit()
                    } else
                    {
                        logEvent(this.analytics, 'AMEX_CI_LOGIN_FAILURE', {
                            content_type: 'userName',
                            content_id: this.userName
                        })
                        this.showAlert = true
                        this.alertMessage = restErrorMessages.get(errorCode, errorDescription)
                        this.alertType = 'error'
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                })
            })
        },
        handleFormSubmit()
        {
            store.set('requestKeys/accessToken', this.loginRes.data.accessToken)
            store.set('requestKeys/ClientCode', this.loginRes.data.keyValues[0].data)
            store.set('requestKeys/refreshToken', this.loginRes.data.accessToken)
            localStorage.setItem('refreshToken', this.loginRes.data.refreshToken)
            localStorage.setItem('userName', this.userName)
            store.set('requestKeys/userName', this.userName)

            const userAbility = [
                {
                    action: 'manage',
                    subject: 'all',
                },
            ]
            this.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            // delete user.ability
            // errorMessages.get()

            logEvent(this.analytics, 'AMEX_CI_LOGIN_SUCCESS', {
                content_type: 'userName',
                content_id: this.userName
            })

            router.push('/');
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.public-contact {
    width: 70%;
    // height: 400px;
    // overflow: hidden;
    margin: auto;
}

.en-forgotid {
    font-size: 31px;
    cursor: pointer;
    position: absolute;
    left: 0;
}

@media screen and (max-width: 809px) {
    .public-contact {
        width: 98%;
        // height: 95vh;
        // overflow: hidden;
    }
}


@media screen and (max-width: 409px) {


    .password {
        margin: 31px 24% 0px;
    }
}



.en-consumer {
    position: absolute;
    color: #fff;
    top: 88px;
    left: 40px;
    right: 40px;
    font-size: 15px;
}
</style>
